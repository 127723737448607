$yellow: #ffb300;
$red: #f23800;
$blue: #00a3bf;
$top-nav-height: 60px;
$side-nav-width: 200px;

@font-face {
  font-family: 'Tofino Wide';
  src: url('./fonts/TofinoProPersonalWide-Medium.woff2') format('woff2'),
      url('./fonts/TofinoProPersonalWide-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Tofino Wide';
  src: url('./fonts/TofinoProPersonalWide-Bold.woff2') format('woff2'),
      url('./fonts/TofinoProPersonalWide-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Tofino';
  src: url('./fonts/TofinoProPersonal-Regular.woff2') format('woff2'),
      url('./fonts/TofinoProPersonal-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Tofino';
  background-color: rgba(0,0,0,.05);
  // width: 100vw;
  // overflow-x: hidden;
}

h1 {
  font-family: 'Tofino Wide';
}
// h1.page-header {
//   margin-top: 1em;
// }
.icon-cont {
  padding: 4px;
  cursor: pointer;
  opacity: .5;
  transition: all .2s; 
  width: 1.5em;
  height: 1.5em;
  line-height: 1.66em;
  text-align: center;
  display: inline-block;
}

.icon-cont:hover {
  opacity: 1;
  background: rgba(0,0,0,.05);
}
.text-link::before {
  content: "";
  position: absolute;
  top: -.5em;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom: 1em solid
  rgba(255, 179, 0, 0);
  box-sizing: border-box;
  background:
  transparent;
  z-index: -1;
  transition: .2s all;
}

.text-link {
  font-family: 'Tofino Wide';
  font-weight: 700;
  font-size:.8rem;
  cursor: pointer;
  line-height: 1;
  padding: 4px;
  transition: .2s all;
  border-bottom: none;
  position: relative;
}
.text-link:hover {
  color: black !important;
}

.text-link

.text-link:hover::before {
  border-bottom: 1em solid
  rgb(255, 179, 0);
}


.side-nav {
  background: rgba(0,0,0,.1);
  width: $side-nav-width;
  padding-top: calc(#{$top-nav-height} / 4);
  height: calc(100vh - #{$top-nav-height});
  overflow-y: auto;
  display: inline-block;
  position: fixed;
  top: $top-nav-height;
  z-index: 1;
}
.content-cont {
  flex: 1;

  .content {
    margin-left: $side-nav-width;
    flex: 1;
    padding: 0 2em;

    h1 {
      margin-top: .25em;
    }
  }
}
.parent {
  display: flex;
  flex-direction: column;
}
main {
  min-height: 100vh;

}

.full {
  width: 100%;
}

.font-wide {
  font-family: 'Tofino Wide';
}
.font-1 {
  font-size: 1rem;
}
.font-1-5 {
  font-size: 1.5rem;
}
.font-2 {
  font-size: 2rem;
}

.font-5 {
  font-size: 5rem;
}

.home-banner {
  font-family: 'Tofino Wide';
}

.narrow {
  max-width: 1040px;
  margin: auto;
}
.bold {
  font-weight: bold;
}

.hidden {
  display: none;
}

.center {
  text-align: center;

}
.padding-1 {
  padding: 1rem;
}
.margin-left-1 {
  margin-left: 1rem;
}
.margin-left-2 {
  margin-left: 2rem;
}
.margin-right-half {
  margin-right: .5rem;
}
.margin-right-1 {
  margin-right: 1rem;
}
.margin-right-2 {
  margin-right: 2rem;
}
.padding-right-1 {
  padding-right: 1rem;
}
.margin-bottom-1 {
  margin-bottom: 1rem;
}
.margin-bottom-2 {
  margin-bottom: 2rem;
}
.flex-cont {
  display: flex;
}
.align-center {
  align-items: center;
}
.flex-1 {
  flex: 1;
}

@keyframes logo-ani {
  0% {
    fill: linear-gradient;
  }
  
  50% {
    fill: lime;
  }
}

body {
  background: white;
}

nav.main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $top-nav-height;


  .logo-cont {
    // width: 100vw;
    // overflow: hidden;
    // display: flex;
    // align-items: center;
    // justify-content: center;

    svg {
      height: 28px;
    }

    path {
      // animation: logo-ani 5s infinite linear;
      fill: black;
    }
  }
  .nav-links-cont {
    flex: 1;
    padding-left: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    a.text-link {
      padding: .66rem;
    }
    .button {
      margin-left: .5rem;
    }
  }
}
.nowrap {
  white-space: nowrap;
}
.button, input[type=submit] {
  white-space: nowrap;
  background:black;
  border: .15em solid black;
  display: inline-flex;
  align-items: center;
  padding: .75em 1em;
  color:white;
  transition: all .2s;
  line-height: 1;
  font-weight: bold;
  cursor: pointer;
  outline: none;
}
.button.red {
  background: $red;
  border-color: $red;
}
.button.red:hover {
  background-color: white;
  color: $red;
  box-shadow: .2em .2em 0 $red;
}
.button:hover, input[type=submit]:hover {
  background-color: white;
  color: black;
  box-shadow: .2em .2em 0 black;
}
.button.disabled, input[type=submit].disabled {
  pointer-events: none;
  opacity: .66;
}

a {
  color: gray;
  text-decoration: none;
}

.card {
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
  background: rgba(255,255,255,.75);
  cursor: pointer;
  transition: .2s all;
  position: relative;
  z-index: 0;
}
.card:last-of-type {
  border: none;
}
.card:hover {
  box-shadow: .2em .2em 0 rgba(0,0,0,.1);
  background: rgba(255,255,255,1);
  transition: .2s all;
  z-index: 1;
}

input[type=text], textarea, input[type=password], .DayPickerInput input, select {
  border: 2px solid rgba(0,0,0,.1);
  padding: 1em;
  width: 100%;
  box-sizing: border-box;
  transition: all .2s;
  outline: none;
}
input[type=text]:focus, textarea:focus, input[type=password]:focus, .DayPickerInput input:focus, select:focus {
  border: 2px solid rgba(0,0,0,1);
}
option {
  border:none;
  outline:none;
}

fieldset {
  border:none;
  outline: none;
  padding: 1em;
  // background: rgba(0,0,0,.05);
  margin-bottom: 1em;
}

input[type="submit"] {
  font-size: 1em;
}

.field-error {

  color: $red;
  font-weight: bold;
  padding: 1em 0;
}
form {
  position: relative;
}

// .spinner, .spinner:after {
//   border-radius: 50%;
//   width: 1em;
//   height: 1em;
// }

.spinner {
  font-size: 1em;
  position: relative;
  // text-indent: -9999em;
  border-radius: 50%;
  width: 0em;
  height: 0em;
  border-left: 1em solid rgba($yellow, 1);
  border-top: 1em solid rgba($red, 1);
  border-right: 1em solid rgba($blue, 1);
  border-bottom: 1em solid rgba(0,0,0, 1);
  transform: translateZ(0);
  animation: load8 .5s infinite linear;
  // background: linear-gradient(0deg, $yellow 0%, $blue 50%, $red 100%);
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
    
  }
  
  100% {
    transform: rotate(360deg);
  }
}


input[type=checkbox], input[type=radio] {
  margin-right: 1em;
}

label.fit {
  flex: unset;
}
label.no-margin {
  margin: 0;
}

label {
  margin-bottom: 1em;
  margin-right: .5em;
  flex: 1;

  span {
    text-transform: uppercase;
    font-family: 'Tofino Wide';
    font-size: .8em;
    color: rgba(0,0,0,.75);
  }
}

table.basic {
  border-collapse: collapse;
  width: 100%;

  tr {

    td {
      padding: .5em;
      border-bottom: 1px solid rgba(0,0,0,.1);
    }
    td.fit {
      width: 1%;
    }
  }

  tr:last-of-type {
    td {
      border-bottom: none;
    }
  }

}
